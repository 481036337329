@font-face {
  font-weight: 400;
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans/DMSans-Regular.ttf") format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans/DMSans-Medium.ttf") format('truetype');
}

@font-face {
  font-weight: bold;
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans/DMSans-Bold.ttf") format('truetype');
}

#root {
  min-height: 100vh;
  padding: 0;
  background-color: #f3f5f7;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}


html {
  scroll-behavior: smooth;
}